export enum GameEvent {
  LAUNCH_REAL_GAME = "LaunchRealGameEvent",
  LAUNCH_DEMO_GAME = "LaunchDemoGameEvent",
  // GAME_SEARCH = "GameSearchEvent",
  // GAME_SEARCH_LAUNCH_DEMO = "GameSearchLaunchDemoGameEvent",
  // GAME_SEARCH_LAUNCH_REAL = "GameSearchLaunchRealGameEvent",
  FAVOURITE_GAME_ADD = "FavouriteGameAddEvent",
  FAVOURITE_GAME_REMOVE = "FavouriteGameRemoveEvent",
  LIKE_GAME = "LikeGameEvent",
  DISLIKE_GAME = "DislikeGameEvent",
  SOCIAL_SHARE = "SocialShareEvent",

  //social share
  // FAVOURITE_GAME_LAUNCH = "FavouriteGameLaunchEvent",
  // GAME_IFRAME_SEARCH = "GameIframeSearchEvent",
}
