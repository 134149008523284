import { Account } from "./AccountEventEnum";
import { BannerEvent } from "./BannerEventEnum";
import { GameEvent } from "./GameEventEnum";
import { ForgotPassword } from "./ForgotPasswordEventEnum";
import { LiveChatEvent } from "./LiveChatEventEnum";
import { LoginEvent } from "./LoginEventEnum";
import { NavigationEvent } from "./NavigationEventEnum";
import { Promotion } from "./PromotionEventEnum";
import { RegistrationEvent } from "./RegistrationEvent";
import { SearchEvent } from "./SearchEventEnum";

export default {
  Account,
  BannerEvent,
  GameEvent,
  ForgotPassword,
  LiveChatEvent,
  LoginEvent,
  NavigationEvent,
  Promotion,
  RegistrationEvent,
  SearchEvent,
};
