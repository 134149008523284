export enum Account {
  MY_ACCOUNT_TELLER = "MyAccountTellerFocusEvent",
  MY_ACCOUNT_HISTORY = "MyAccountHistoryFocusEvent",
  MY_ACCOUNT_SETTINGS = "MyAccountSettingsFocusEvent",
  MY_ACCOUNT_LOGOUT = "LogOutInitiatedEvent",
  DOC_VERIFICATION_SUBMIT = "DocVerificationItemSubmittedEvent",
  DOC_VERIFICATION_ERROR = "DocVerificationErrorEvent",
  MY_ACCOUNT_DETAILS_UPDATE_ACTION = "MyAccountDetailsActionEvent",
  MY_ACCOUNT_PASSWORD_UPDATE_ACTION = "MyAccountPasswordActionEvent",
  MY_ACCOUNT_SETTINGS_UPDATE_ACTION = "MyAccountSettingsActionEvent",
}
